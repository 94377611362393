export function cleanJsonLdObject(jsonLd: any) {
  for (const key in jsonLd) {
    if (
      jsonLd[key] === null ||
      jsonLd[key] === undefined ||
      jsonLd[key] === ""
    ) {
      delete jsonLd[key]
    } else if (typeof jsonLd[key] === "object") {
      let hasValidChildren = _cleanJsonLdObject(jsonLd[key])
      if (!hasValidChildren) {
        delete jsonLd[key]
      }
    }
  }
  return jsonLd
}

function _cleanJsonLdObject(jsonLd: any) {
  let hasOneValidAttribute = false
  for (const key in jsonLd) {
    if (
      jsonLd[key] === null ||
      jsonLd[key] === undefined ||
      jsonLd[key] === ""
    ) {
      delete jsonLd[key]
    } else if (typeof jsonLd[key] === "object") {
      let hasValidChildren = _cleanJsonLdObject(jsonLd[key])
      if (hasValidChildren) {
        hasOneValidAttribute = true
      } else {
        delete jsonLd[key]
      }
    } else {
      hasOneValidAttribute = true
    }
  }
  return hasOneValidAttribute
}
